<script>
import PageHeader from '../../SettingsSubPageHeader.vue';
import WppConnectForm from './WppConnectForm.vue';
export default {
  components: {
    PageHeader,
    WppConnectForm,
  },
  data() {
    return {
      provider: 'whatsapp_cloud',
    };
  },
};
</script>

<template>
  <div class="wizard-body small-9 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.WHATSAPP.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.WHATSAPP.DESC')"
    />

    <wpp-connect-form />
  </div>
</template>